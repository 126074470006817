
import Vue from "vue";
import {
  FieldEmail,
  FieldPassword,
  FieldPhone,
  FieldUser,
} from "@/components/account";
import InputCompanies from "@/components/InputCompanies.vue";
import AllowIf from "@/components/AllowIf";
// import BtnCreateOcupation from "@/views/Settings/Nomenclators/BtnCreateOcupation.vue";
import FieldOcupations from "@/components/account/FieldOcupations.vue";
import store from "@/store/index";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import { MUT_NOTIFICATION } from "@/store/mutations/types";
import rules from "@/components/account/rules";
import { notifyError, notifySuccess } from "@/components/Notification";
import { AxiosError, AxiosResponse } from "axios";
import { ResponseStatus } from "@/models/ResponseStatusEnum";
import MaTimePicker from "@/components/MaTimePicker.vue";
import moment from "moment";
import { getAPI } from "@/api/axios-base";

export default Vue.extend({
  name: "btn-create-employee",
  components: {
    FieldEmail,
    FieldPassword,
    //FieldPhone,
    FieldUser,
    InputCompanies,
    AllowIf,
    FieldOcupations,
    // BtnCreateOcupation,
    MaTimePicker,
  },
  data() {
    return {
      dialog: false,
      passwordRetry: "",
      formValid: false,
      rules: {
        ...rules,
        maxCaracters: (v: string) => v.length >= 3 || this.$t("min3Chars"),
        maxCaractersfull: (v: string) => v.length >= 5 || this.$t("min5Chars"),
      },
      ocupattions: [],
      loadingOcupations: false,

      form: {
        company: "",
        email: "",
        password: "",
        username: "",
        fullname: "",
        phone: "",
        rol: "WORKER",
        ocupationId: 0,
        /* payXhour: 0,
        hoursToWork: 0, */
        origPhone: "",
        ext: "",
        startDate: null,
        endDate: null,
      },
    };
  },
  computed: {
    ...mapState("crmConfigurationsModule", ["phonesFree"]),
    ...mapState("crmEmployeeModule", ["loading"]),
    ...mapState("crmCompaniesModule", ["companies"]),
    ...mapGetters({ methodCall: "getMethodCalls" }),
    ...mapGetters(["isSuper", "isAdmin", "isWorker", "isDoctor"]),
    passwordIsRight(): boolean {
      const { passwordRetry, form } = this;
      const same = passwordRetry == form.password;
      const notEmpty = passwordRetry.trim() != "" && form.password.trim() != "";
      // const maxLength = passwordRetry.length >= 8 && form.password.length >= 8;

      return same && notEmpty;
    },
    samePassword(): boolean {
      return this.passwordRetry.trim() == this.form.password.trim();
    },
    enableCreate(): boolean {
      return this.passwordIsRight && this.formValid;
    },
    showForm(): boolean {
      return (this.companies.length > 0 && this.isSuper) || this.isAdmin;
    },
  },
  async mounted() {
    if (store.getters.isSuper) {
      this.init();
    }
    await this.getNumbers();
  },
  methods: {
    ...mapMutations([MUT_NOTIFICATION]),
    ...mapMutations("crmEmployeeModule", ["mutLoading"]),
    ...mapActions("crmCompaniesModule", ["actGetCompanies"]),
    ...mapActions("crmEmployeeModule", [
      "actCreateEmployee",
      "actGetEmployees",
    ]),
    ...mapActions("crmConfigurationsModule", [
      "actGetNumbers",
      "actAssignNumber",
    ]),
    async getNumbers() {
      await this.actGetNumbers();
    },
    cancelAdd() {
      this.mutLoading(false);
      this.$router.push(`/employees`);
    },

    create() {
      let body: any = {};
      body = Object.assign({}, this.form);

      if (!this.isSuper) {
        delete body.company;
      }

      if (body.startDate) {
        const h = body.startDate.split(":")[0];
        const m = body.startDate.split(":")[1];
        const dateStart = moment()
          .startOf("day")
          .add(Number(h), "hour")
          .add(Number(m), "minute");
        body.startDate = dateStart.toISOString();
      }
      if (body.endDate) {
        const h = body.endDate.split(":")[0];
        const m = body.endDate.split(":")[1];
        const dateStart = moment()
          .startOf("day")
          .add(Number(h), "hour")
          .add(Number(m), "minute");
        body.endDate = dateStart.toISOString();
      }

      if (body.phone != "" && body.phone != undefined) {
        body.phone = "+1" + body.phone;
      }
      /*   if (body.payXhour != 0) {
        body.payXhour = Number(body.payXhour);
      }

      if (body.hoursToWork != 0) {
        body.hoursToWork = Number(body.hoursToWork);
      } */
      if (body.origPhone != "") {
        if (!body.origPhone.includes("+1")) {
          body.origPhone = "+1" + body.origPhone;
        }
      }

      body = this.cleanNull(body);
      this.mutLoading(true);
      getAPI
        .post("/users/employee/create", body)
        .then(() => {
          notifySuccess("Employee create succes successfully");
          this.mutLoading(false);
          this.cancelAdd();
          this.resetForm();
        })
        .catch((error) => {
          let mess = error.response.data.message.toString();
          notifyError(error.response.data, `An error occurred: ${mess}`);
          this.mutLoading(false);
        });
    },
    resetForm(): void {
      this.passwordRetry = "";

      this.form = {
        company: "",
        email: "",
        password: "",
        username: "",
        fullname: "",
        phone: "",
        rol: "WORKER",
        ocupationId: 0,
        /*  payXhour: 0,
        hoursToWork: 0, */
        origPhone: "",
        ext: "",
        startDate: null,
        endDate: null,
      };

      if (this.$refs.form) {
        (this as any).$refs.form.resetValidation();
      }
    },
    init() {
      this.actGetCompanies();
    },

    cleanNull(obj: any) {
      for (var propName in obj) {
        if (
          obj[propName] === null ||
          obj[propName] === undefined ||
          obj[propName] === ""
        ) {
          delete obj[propName];
        }
      }
      return obj;
    },
  },
});
